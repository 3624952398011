<script setup lang="ts">
import type { Ref } from 'vue'
import type { DashboardFilters } from '~/composables/useAPI/useApiDashboard'
import { DashboardFiltersSymbol } from '@dashboard'

const modelValue = defineModel<string | null>({
  default: null,
})

const filters = inject<Ref<DashboardFilters>>(DashboardFiltersSymbol)!

const divisionId = getCurrentDivisionId()
const { data } = useApiDashboardFilter(divisionId, 'sector', filters)

const sectors = computed(() =>
  (data.value ?? []).map((v) => {
    let label = v
    let value: string | null = v

    if (value === '[all]') {
      label = 'Show all'
      value = null
    } else if (label === 'Unknown') {
      label = 'Sector not specified'
    }

    return { label, value }
  }),
)
</script>

<template>
  <div v-if="sectors.length > 2">
    <FormKit
      v-model="modelValue"
      type="listbox"
      :options="sectors"
      placeholder="Select"
      outer-class="w-56"
      :value="null"
    >
      <template #prefix>
        <div
          class="flex items-center rounded-l bg-gray-700 px-2.5 pt-px text-xs"
        >
          Sector
        </div>
      </template>
    </FormKit>
  </div>
</template>

<style lang="postcss" scoped>
:deep(.formkit-inner) {
  @apply backdrop-blur-xl backdrop-saturate-[180%];
}
</style>
